import { css } from '@emotion/react'

import WorksCard from 'src/components/molecules/WorksCard'

import { HomeworksQuery } from 'generated/graphql.api'

type Props = {
  data: HomeworksQuery
}

const WorksList: React.FC<Props> = ({ data }) => {
  const workList = data.page.edges

  return (
    <>
      <div css={styles.cardContainer}>
        {workList.map(data => (
          <div key={data.node.title} css={styles.cardWrapper}>
            <WorksCard
              client={data.node.client}
              title={data.node.title}
              categories={data.node.category}
              src={data.node.listimage.url}
              url={data.node.slug}
            />
          </div>
        ))}
      </div>
    </>
  )
}

const styles = {
  cardContainer: css`
    width: 100%;
    padding: 50px 0 150px;
    @media (max-width: 1200px) {
      padding: 50px 0 100px;
    }
  `,
  cardWrapper: css`
    padding-right: 150px;
    @media (max-width: 1200px) {
      padding-right: 15px;
    }
    @media (max-width: 960px) {
      padding-right: 0;
    }
  `,
}

export default WorksList
