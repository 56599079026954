import Image from 'next/image'
import Link from 'next/link'
import { css, Theme } from '@emotion/react'
import { Box, Typography } from '@mui/material'

type Props = {
  client: string
  title: string
  src: string
  categories: string[]
  url: string
}

const WorksCard: React.FC<Props> = ({
  client,
  title,
  src,
  categories,
  url,
}) => {
  return (
    <Box css={styles.container}>
      <Link href={`/works/${url}`} passHref>
        <a>
          <Box css={styles.imgContainer}>
            <Image
              src={src}
              layout='fill'
              objectFit='cover'
              css={styles.image}
            />
          </Box>
          <Box css={styles.contentContainer}>
            <Typography variant='h2' component='h2' css={styles.client}>
              {client}様
            </Typography>
            <Typography variant='h2' component='h2' css={styles.title}>
              {title}
            </Typography>
            <Box css={styles.categoryWrapper}>
              {categories.map(category => (
                <Typography
                  variant='body1'
                  css={styles.category}
                  key={category}>
                  {category},
                </Typography>
              ))}
            </Box>
          </Box>
        </a>
      </Link>
    </Box>
  )
}

const styles = {
  container: css`
    height: 700px;
    width: 33.3%;

    @media (max-width: 1200px) {
      height: 60vw;
    }
    @media (max-width: 768px) {
      width: 100%;
      height: 700px;
    }
  `,
  imgContainer: css`
    overflow: hidden;
    height: 80%;
    width: 100%;
    will-change: transform;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    &:before {
      z-index: 10;
      background-color: rgba(0, 0, 0, 0);
      transition: background-color 0.5s ease;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
    }
    &:hover:before {
      background-color: rgba(255, 255, 255, 0.3);
    }
    &:hover img {
      transform: scale(1.1);
    }
  `,
  image: css`
    transition: transform 0.5s ease-in-out;
  `,
  contentContainer: css``,
  client: (theme: Theme) => css`
    font-family: 'Noto sans JP', sans-serif;
    font-size: 0.7rem;
    font-weight: 700;
    color: ${theme.palette.primary.main};
    padding: 10px 0 5px;
  `,
  title: (theme: Theme) => css`
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 0.9rem;
    font-weight: 700;
    color: ${theme.palette.primary.main};
    padding: 5px 0;
    @media (max-width: 768px) {
      font-size: 1.2rem;
    }
  `,
  categoryWrapper: css`
    display: flex;
    padding: 5px 0;
  `,
  category: css`
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 0.8rem;
    font-weight: 500;
    margin-right: 5px;
    color: #5f5f5f;
    @media (max-width: 768px) {
      font-size: 0.8rem;
    }
  `,
}

export default WorksCard
